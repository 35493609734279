import { CloseIcon } from '@c/icons';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import useNavigationEvent from '@util/hooks/useNavigationEvent';
import { cva } from 'class-variance-authority';
import { Fragment, Suspense, useCallback } from 'react';
import { Montserrat } from 'next/font/google';

const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
});

const styles = cva(
  'text-[1.5rem] flex flex-col relative  text-left text-brand-black shadow-xl transition-all max-h-screen',
  {
    variants: {
      isFullScreen: {
        true: 'w-full h-full',
        false:
          'w-fit xl:max-w-[90%] min-h-[32rem] min-w-[32rem] p-[2.4rem] rounded-2xl mx-4',
      },
      overflowType: {
        auto: 'overflow-auto',
        visible: 'overflow-y-visible',
        hidden: 'overflow-hidden',
      },
      bgTransparent: {
        true: 'bg-black/70',
        false: 'bg-white',
      },
      noPadding: {
        true: '!p-0',
        false: '',
      },
      modalRounded: {
        true: 'rounded-[3rem]',
        false: '',
      },
    },
  }
);
export interface BaseModalProps {
  isOpen: boolean;
  dismiss?: () => void;
  children?: React.ReactNode;
  title?: React.ReactNode;
  titleCentered?: boolean;
  removeTitleMargin?: boolean;
  hideCloseIcon?: boolean;
  isFullScreen?: boolean;
  disableBackdropDismiss?: boolean;
  noPadding?: boolean;
  overflowType?: 'auto' | 'visible' | 'hidden';
  bgTransparent?: boolean;
  className?: string;
  modalRounded?: boolean;
  titleClassName?: string;
  showBackArrow?: boolean;
  backArrowClassName?: string;
  backArrowOnClick?: () => void;
}

function BaseModal({
  children,
  title,
  hideCloseIcon,
  isOpen,
  dismiss,
  titleCentered = false,
  removeTitleMargin = false,
  isFullScreen = false,
  disableBackdropDismiss = false,
  overflowType = 'auto',
  bgTransparent = false,
  noPadding = false,
  className,
  modalRounded = false,
  titleClassName,
  showBackArrow = false,
  backArrowClassName,
  backArrowOnClick,
}: BaseModalProps) {
  const showTitleSection = !!title || !hideCloseIcon;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-[70] ${className}`}
        onClose={() => dismiss?.()}
        static={disableBackdropDismiss}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {disableBackdropDismiss ? (
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          ) : (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          )}
        </TransitionChild>

        <div
          className={`fixed inset-0 z-10 overflow-y-auto ${montserrat.className}`}
        >
          <div className="flex h-full items-center justify-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={styles({
                  isFullScreen,
                  overflowType,
                  bgTransparent,
                  noPadding,
                  modalRounded,
                })}
              >
                {showTitleSection && (
                  <div
                    className={`${
                      !removeTitleMargin && 'mb-[2.4rem]'
                    } relative mt-[1.6rem] flex w-full items-center sm:mt-0 ${
                      !title ? 'justify-end' : ''
                    } ${titleClassName || ''}`}
                  >
                    {!!title && (
                      <>
                        {showBackArrow && (
                          <Button
                            type="text"
                            leadingIcon={<ChevronLeftIcon />}
                            width="small"
                            onClick={backArrowOnClick}
                            className={backArrowClassName}
                          />
                        )}

                        <div
                          className={`${
                            titleCentered
                              ? 'absolute left-1/2 -translate-x-1/2'
                              : ''
                          } w-fit`}
                        >
                          <DialogTitle>{title}</DialogTitle>
                        </div>
                      </>
                    )}
                    {!hideCloseIcon && (
                      <div className="ml-auto">
                        <Suspense>
                          <BaseModalCloseButtoon
                            dismiss={dismiss}
                            bgTransparent={bgTransparent}
                          />
                        </Suspense>
                      </div>
                    )}
                  </div>
                )}
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React from 'react';
import Button from '@ui/Button';
import { ChevronLeftIcon } from 'lucide-react';

const BaseModalCloseButtoon = ({
  dismiss,
  bgTransparent,
}: {
  dismiss?: () => void;
  bgTransparent?: boolean;
}) => {
  useNavigationEvent(
    useCallback(({ url, previousUrl }) => {
      if (!url || !previousUrl) return;
      if (url !== previousUrl) {
        dismiss?.();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );
  return (
    <button
      className={`mx-10 ${bgTransparent ? 'text-white' : 'text-black'}`}
      type="button"
      onClick={() => dismiss?.()}
    >
      <CloseIcon />
    </button>
  );
};

export default BaseModal;
