import {
  ReadonlyURLSearchParams,
  usePathname,
  useSearchParams,
} from 'next/navigation';
import { useEffect } from 'react';
import usePrevious from './usePrevious';

export type NavigationEventCallbackArgs = {
  url: string;
  previousUrl: string;
  searchParams: ReadonlyURLSearchParams | null;
  previousSearchParams: ReadonlyURLSearchParams | null;
};
const useNavigationEvent = (
  callback: (args: NavigationEventCallbackArgs) => void
) => {
  const pathname = usePathname();
  const prevPathname = usePrevious(pathname);
  const searchParams = useSearchParams();
  const prevSearchParams = usePrevious(searchParams);

  useEffect(() => {
    callback({
      url: pathname ?? '',
      previousUrl: prevPathname ?? '',
      searchParams: searchParams ?? null,
      previousSearchParams: prevSearchParams ?? null,
    });
  }, [callback, pathname, prevPathname, prevSearchParams, searchParams]);
};

export default useNavigationEvent;
