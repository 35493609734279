import {
  type BankAccount,
  type Card,
  type StripeAddressElementChangeEvent,
} from '@stripe/stripe-js';
import { FirebaseCallable, functions, httpsCallable } from '@util/firebase';
import { logError } from '@util/logError';
import { AddressDocument, addressSchema } from '@models/address';
import { StripeAddressSchema } from '@util/types/firestore/stripe';
import { z } from 'zod';
import Stripe from 'stripe';
import { Coupon, couponSchema } from 'models/coupon';

export const createSellerIndividualSchema = z.object({
  address: StripeAddressSchema,
  dob: z
    .object({
      day: z.number(),
      month: z.number(),
      year: z.number(),
    })
    .optional(),
  email: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  phone: z.string(),
  ssn_last_4: z.string().optional(),
  id_number: z.string().optional(),
  title: z.string().optional(),
  relationship: z
    .object({
      title: z.string(),
      owner: z.boolean(),
    })
    .optional(),
});

export const createSellerCompanySchema = z.object({
  address: StripeAddressSchema,
  name: z.string(),
  tax_id: z.string(),
  phone: z.string().optional(),
});

const ExternalAccountSchema = z.object({
  object: z.string(),
  country: z.string(),
  currency: z.string(),
  account_holder_name: z.string().optional(),
  account_holder_type: z.string().optional(),
  routing_number: z.string().optional(),
  account_number: z.string(),
});

export const createSellerArgsSchema = z.object({
  business_type: z.string().optional().default('individual'),
  country: z.string().optional().default('US'),
  email: z.string(),
  individual: createSellerIndividualSchema.optional(),
  company: createSellerCompanySchema.optional(),
  external_account: z.union([z.string(), ExternalAccountSchema]),
});

export type CreateSellerArgs = z.infer<typeof createSellerArgsSchema>;

export const createSellerResponse = z.object({
  id: z.string(),
});

export type CreateSellerResponse = z.infer<typeof createSellerResponse>;

export function createSeller(args: CreateSellerArgs) {
  return httpsCallable<CreateSellerArgs, CreateSellerResponse>(
    functions,
    FirebaseCallable.createSeller
  )(args);
}

type GetPromoseArgs = {
  code: string;
  customer: string;
  total: number;
  subtotal: number;
  account_ids?: string[];
};

const promotionCodeSchema = z.object({
  id: z.string(),
  object: z.string(),
  active: z.boolean(),
  name: z.string(),
  code: z.string(),
  coupon: couponSchema,
  created: z.number(),
  customer: z.null(),
  expires_at: z.null(),
  livemode: z.boolean(),
  max_redemptions: z.null(),
  metadata: z.object({
    order_id: z.string(),
  }),
  restrictions: z.object({
    first_time_transaction: z.boolean(),
    minimum_amount: z.null(),
    minimum_amount_currency: z.null(),
  }),
  times_redeemed: z.number(),
});

const getPromoResponseSchema = z.array(promotionCodeSchema);

export type GetPromoResponse = z.infer<typeof getPromoResponseSchema>;
export type PromotionCode = z.infer<typeof promotionCodeSchema>;

export async function getPromos(args: GetPromoseArgs): Promise<
  {
    coupon: {
      id: string;
      name: string;
      amount_off?: number;
      percent_off?: number;
      account_id?: string;
    };
    active: boolean;
  }[]
> {
  const res = await httpsCallable<
    {},
    {
      coupon: {
        id: string;
        name: string;
        amount_off?: number;
        percent_off?: number;
        account_id?: string;
      };
      active: boolean;
    }[]
  >(
    functions,
    FirebaseCallable.getPromos
  )(args);
  return res.data;
}

export async function getExternalAccounts() {
  // default_for_currency is a property that is custom to stripe connect but isn't included in the types for stripe-js :/ https://stripe.com/docs/api/external_account_cards/object#account_card_object-default_for_currency
  const res = await httpsCallable<
    {},
    {
      data: (
        | (BankAccount & { default_for_currency: boolean })
        | (Card & { default_for_currency: boolean })
      )[];
    }
  >(functions, FirebaseCallable.getExternalAccounts)();
  return res.data;
}

export async function makeDefaultExternalAccount(id: string) {
  try {
    await httpsCallable<{ id: string }, {}>(
      functions,
      // 'makeDefaultExternalAccount'
      FirebaseCallable.makeDefaultExternalAccount
    )({ id });
    return { success: true };
  } catch (e: unknown) {
    logError(e);
    // return message if e has a message else return a generic message
    return {
      error: (e as { message?: string })?.message ?? 'Something went wrong',
    };
  }
}

export async function deleteExternalAccount(id: string) {
  try {
    await httpsCallable<{ id: string }, {}>(
      functions,
      FirebaseCallable.removeExternalAccount
    )({ id });
    return { success: true };
  } catch (e: unknown) {
    logError(e);
    // return message if e has a message else return a generic message
    return {
      error: (e as { message?: string })?.message ?? 'Something went wrong',
    };
  }
}

interface PaymentIntent {
  data: string;
  pi_id: string;
  ch_id?: string;
}

interface createPaymentIntentForLabelArgs {
  amount: number;
  customer_id: string;
  metadata: {
    is_label: 'true' | 'false';
    is_bike?: 'true' | 'false';
    order_id?: string;
    rate_id?: string;
    to_zip?: string;
    from_zip?: string;
  };
  payment_method: string;
  confirm?: boolean;
}
export async function createPaymentIntentForLabel({
  amount,
  metadata,
  customer_id,
  payment_method,
  confirm,
}: createPaymentIntentForLabelArgs) {
  const res = await httpsCallable<
    createPaymentIntentForLabelArgs,
    PaymentIntent
  >(
    functions,
    FirebaseCallable.createPaymentIntentForLabel
  )({ amount, metadata, customer_id, payment_method, confirm });
  return res;
}

// Helpers
export function stripeAddressToAddressDocument(
  e: StripeAddressElementChangeEvent
): AddressDocument {
  const [firstName, ...lastName] = e.value.name.split(' ');
  return addressSchema.parse({
    name: firstName + ' ' + lastName.join(' '),
    phone: e.value.phone,
    city_locality: e.value.address.city ?? '',
    country_code: e.value.address.country,
    address_line1: e.value.address.line1,
    address_line2: e.value.address.line2 ?? '',
    state_province: e.value.address.state,
    postal_code: e.value.address.postal_code,
  });
}

export async function createSellerCoupon(params: Coupon) {
  return await httpsCallable<Coupon, { id: string }>(
    functions,
    'v2_createCoupon'
  )(params);
}

export async function retrieveSellerPromoCodes() {
  return await httpsCallable<{}, Stripe.PromotionCode[]>(
    functions,
    FirebaseCallable.retrieveSellerPromoCodes
  )({});
}

export async function toggleSellerPromoCode(id: string, active: boolean) {
  return await httpsCallable<{ promoId: string; active: boolean }, {}>(
    functions,
    FirebaseCallable.toggleSellerPromoCode
  )({ promoId: id, active });
}

export async function fetchSubscriptionInfo(subscription_id?: string) {
  if (!subscription_id) return null;
  return await httpsCallable<{ subscription_id: string }, Stripe.Subscription>(
    functions,
    'v2_getSubscriptionInfo'
  )({ subscription_id });
}

export async function fetchInvoices(subscription_id?: string) {
  if (!subscription_id) return null;
  return await httpsCallable<
    { subscription_id: string },
    {
      data: Stripe.Invoice[];
    }
  >(
    functions,
    'v2_getInvoices'
  )({ subscription_id });
}

export async function getPricesAndProducts({
  type,
}: {
  type: 'ebay' | 'shopify';
}) {
  const res = await httpsCallable<
    {
      type: 'ebay' | 'shopify';
    },
    {
      prices: Stripe.Price[];
      product: Stripe.Product;
    }
  >(
    functions,
    'v2_getPricesAndProducts'
  )({
    type,
  });
  return res.data;
}
export async function createSubscription({
  price_id,
  payment_method_id,
  type,
}: {
  price_id: string;
  payment_method_id: string;
  type: 'ebay' | 'shopify';
}) {
  return await httpsCallable<
    { price_id: string; payment_method_id: string; type: 'ebay' | 'shopify' },
    { id: string }
  >(
    functions,
    'v2_createSubscription'
  )({ price_id, payment_method_id, type });
}
