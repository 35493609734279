import { z } from 'zod';

export const StripeAddressSchema = z.object({
  city: z.string(),
  country: z.string(),
  line1: z.string(),
  line2: z.string().optional(),
  postal_code: z.string(),
  state: z.string(),
});

export const StripeAccountSchema = z.object({
  email: z.string(),
  individual: z.object({
    id_number: z.string(),
    address: StripeAddressSchema,
    dob: z
      .object({
        day: z.number(),
        month: z.number(),
        year: z.number(),
      })
      .optional(),
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    phone: z.string(),
    ssn_last_4: z.string().optional(),
    title: z.string(),
    relationship: z
      .object({
        title: z.string(),
        owner: z.boolean(),
      })
      .optional(),
  }),
  business_type: z.string().optional().default('individual'),
  country: z.string().optional().default('US'),
});

export type StripeAccount = z.infer<typeof StripeAccountSchema>;
export type StripeAddress = z.infer<typeof StripeAddressSchema>;
