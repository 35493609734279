import { loadStripe, type Stripe } from '@stripe/stripe-js';
import { createContext, useContext } from 'react';

type StripeContext = {
  stripePromise: PromiseLike<Stripe | null> | Stripe | null;
};
const StripeContext = createContext({} as StripeContext);
export function useStripeContext() {
  return useContext(StripeContext);
}

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PK!);

export function StripeProvider({ children }: any) {
  return (
    <StripeContext.Provider value={{ stripePromise }}>
      {children}
    </StripeContext.Provider>
  );
}
